<template>
	<div
		class="box box--error relative"
		:class="{
			'box--no-border': !border,
			'pr-12': closeable,
		}"
	>
		<div class="flex">
			<div class="flex-no-shrink mr-2">
				<svg-icon class="w-6 h-6 box__icon icon--red" name="error" />
			</div>
			<div class="box__message flex-1">
				<div v-if="html" v-html="html" />
				<slot />
			</div>
			<div v-if="closeable" class="absolute top-0 right-0">
				<button class="py-2 px-3" @click="$emit('close')">
					<svg-icon class="w-4 h-4 fill-current text-red-darker" name="cross" />
					<span class="sr-only">{{ $t('CLOSE') }}</span>
				</button>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: 'box-error',
	props: {
		border: {
			type: Boolean,
			default: true,
		},
		html: {
			type: String,
			default: '',
		},
		closeable: {
			type: Boolean,
			default: false,
		},
	},
}
</script>
